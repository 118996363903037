.user-meta-card{
    padding: 12px 6px;
    border-radius: 8px;
    border: 3px solid #bed6e288;
    p, h6{
        margin-bottom: 0;
    }

}

.user-popup{
    p{
        margin-bottom: 0;
    }
}