.outline-card{
    padding: 12px 6px;
    border-radius: 8px;
    border: 3px solid #bed6e288;
    p, h6{
        margin-bottom: 0;
    }

    
    &:hover{
        border-color: #348db9b6;
    }
}