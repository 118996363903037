body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

$theme-colors: (
  "primary": #005477,
  "danger": #aa4465,
);

@import "../node_modules/bootstrap/scss/bootstrap.scss";

.border-right {
  border-right: 1px solid gray;
}
.font-bold {
  font-weight: bold;
}

.isClickable {
  cursor: pointer;
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.request-pending {
  height: 50vh;
}

@import "./styles/index.scss";

.btn-sm {
  font-size: 12px;
}

// ck editor
.ck.ck-editor__main {
  .ck.ck-content {
    h1:first-child {
      display: none;
    }
    p {
      margin-bottom: 0;
    }
  }
}
