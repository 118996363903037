.video-card {
    background-color: #005380;
    height: 100%;
    box-shadow: 0 20px 40px rgba(97, 129, 129, 0.1);
    // border-radius: 8px;
    overflow: hidden;
    outline: 4px solid transparent;
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 10px 20px rgba($color: #005380, $alpha: 0.4);
    cursor: pointer;

    .content-brief {
        padding: 10px 10px;
        text-underline-offset: 0.4em;
        line-height: 18px;
        text-decoration: transparent;
        transition: text-underline-offset 400ms, text-decoration-color 400ms;
    }
    iframe {
        transition: all 0.2s ease-in-out;
        border-radius: 10px 10px 0 0px;
        border-radius: 0;
        // border: 4px solid #356be0;
        // box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.08), 0 0 0 1px rgba(10, 10, 10, 0.02);
    }
    &:hover {
        // outline: 5px solid #fd9197;
        // transform: translateY(-4px) scale(1.01);
        z-index: 5000 !important;
        box-shadow: none;
        transform: translateY(-3px);
        iframe {
            transform: scale(1.05);
        }
        .content-brief {
            text-decoration: underline;
            text-underline-offset: 0.1em;
        }
    }
}
.resources {
    .btn-filter {
        padding: 6px 18px;
        border-radius: 50px;
        transition: all 0.3s ease;
        font-size: 14px;
        line-height: 14px;
        font-weight: 600;
    }
    .btn-outline-primary.btn-filter {
    }
    .btn-outline-primary.btn-filter.odd {
        &:hover,
        &.active {
            background: transparent !important;
            color: #fff !important;
        }
        &.active {
            button.dropdown-toggle.btn.btn-primary {
                background: linear-gradient(to right, #005380, #0978b4) !important;
                color: #fff !important;
            }
        }

        button.dropdown-toggle.btn.btn-primary {
            font-weight: 600;
            padding: 6px 18px !important;
            border-radius: 50px !important;
            transition: all 0.2s ease;
            font-size: 14px;
            line-height: 14px;
            background: transparent !important;
            color: #005380 !important;
            &:hover,
            &.active {
                background: linear-gradient(to right, #005380, #0978b4) !important;
                color: #fff !important;
            }
        }
    }

    .video-card {
        background-color: #005380;
        height: 100%;
        box-shadow: 0 20px 40px rgba(97, 129, 129, 0.1);
        // border-radius: 8px;
        overflow: hidden;
        outline: 4px solid transparent;
        transition: all 0.3s ease-in-out;
        box-shadow: 0px 10px 20px rgba($color: #005380, $alpha: 0.4);
        cursor: pointer;

        .content-brief {
            padding: 20px 20px 28px;
            text-underline-offset: 0.4em;
            line-height: 18px;
            text-decoration: transparent;
            transition: text-underline-offset 400ms, text-decoration-color 400ms;
        }
        iframe {
            transition: all 0.2s ease-in-out;
            border-radius: 10px 10px 0 0px;
            border-radius: 0;
            // border: 4px solid #356be0;
            // box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.08), 0 0 0 1px rgba(10, 10, 10, 0.02);
        }
        &:hover {
            // outline: 5px solid #fd9197;
            // transform: translateY(-4px) scale(1.01);
            z-index: 5000 !important;
            box-shadow: none;
            transform: translateY(-3px);
            iframe {
                transform: scale(1.05);
            }
            .content-brief {
                text-decoration: underline;
                text-underline-offset: 0.1em;
            }
        }
    }

    .resource-card {
        height: 100%;
        transition: all 0.2s ease-in-out;
        overflow: hidden;
        cursor: pointer;
        .info {
            color: #fff;
            p {
                font-size: 14;
                margin-bottom: 4px;
                letter-spacing: 6px;
                font-weight: 300;
            }
            .title {
                height: 80px;
            }
        }
        img {
            transition: all 0.2s ease-in-out;
      
        }
        .img-wrapper{
            height: 190px;
            overflow: hidden;
        }
        .arrow-icon {
            transition: all 0.2s ease-in-out;
        }
        .underline-animation {
            padding: 0.2em 0;
            overflow: hidden;
            // display: block;
            position: relative;
            padding: 0.2em 0;
        }
        &:hover {
            // transform: translateY(-4px);
            img {
                transform: scale(1.1);
            }
            .arrow-icon {
                transform: translateX(5px);
            }

            .underline-animation::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 0.08em;
                background-color: #fff;
                // opacity: 0;
                transition: opacity 300ms, transform 300ms;
                opacity: 1;
                transform: translate3d(0, 0, 0);
            }
        }
    }
}
.underline-animation {
    padding: 0.2em 0;
    overflow: hidden;
    // display: block;
    position: relative;
    padding: 0.2em 0;

    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0.1em;
        background-color: #fff;
        // opacity: 0;
        transition: opacity 300ms, transform 300ms;
        opacity: 0;
        transform: translate3d(-100%, 0, 0);
    }
    &:hover::after {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}
