.partner-card {
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    border: 3px solid #bed6e288;
    transition: all cubic-bezier(0.39, 0.575, 0.565, 1) 0.3s;

    &:hover {
        box-shadow: 0 20px 40px rgba(116, 211, 255, 0.1);
        background-color: #fff;
        border: 3px solid #005380;
        color: #005380;
        // border: 2px solid #a31d23;
        // color: #a31d23;

        a {
            color: #fff !important;
            background-color: #005380;
            // background-color: #a31d23;
        }
    }
}
.draft-editor-container{
    border-radius: 4px;
    border: 1px solid rgba(104, 104, 104, 0.445);
    // padding: 2px;
    padding-top: 2px;
    min-height: 280px;
}


